import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ErrorInfo, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ReactGA from 'react-ga4';

import {
  ErrorFallbackComponent,
  handleError as originalHandleError,
  withErrorBoundary,
  withErrorHandler,
} from '@utils/errorHandler';

import Layout from '../components/layout';
import { AppContextProvider } from '../contexts/AppContext';

import '../styles/styles.css';

export const sharedQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Keep a shared global cache of requests to reduce load on the API
      staleTime: 6000,
    },
  },
});

const handleRouteChange = withErrorHandler((url: string) => {
  if (ReactGA._hasLoadedGA) {
    ReactGA.send({ hitType: 'pageview', page: url });
  }
});

// Wrapper for ErrorBoundary's onError to conform to the expected type
function handleErrorWrapper(error: Error, info: ErrorInfo) {
  const componentStack = info.componentStack ?? ''; // Handle null by providing an empty string
  originalHandleError(error, { componentStack });
}

const App = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();

  // ensures each request has its own cache:
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            // With SSR, we usually want to set some default staleTime
            // above 0 to avoid refetching immediately on the client
            staleTime: 6000,
          },
        },
      })
  );

  useEffect(
    withErrorBoundary(() => {
      router.events.on('routeChangeComplete', handleRouteChange);
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange);
      };
    }),
    [router]
  );

  return (
    <ErrorBoundary
      fallback={<ErrorFallbackComponent />}
      onError={handleErrorWrapper}
    >
      <Head>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, maximum-scale=1'
        />
        <meta
          name='norton-safeweb-site-verification'
          content='YISK7B41ZI1PCHNPPNPQWKK-TX7YRK0GONAFDT5-4YVCW6DGWS6Z7CDQS1KBVQMJCEUWO75YFP4UOYA03CPPLJ30RV-KNOOS6ZFW4EFTKEHJ8SSPPHOX2DDQEJT9C2X7'
        />
      </Head>
      <Layout>
        <QueryClientProvider client={queryClient}>
          <AppContextProvider>
            <Component {...pageProps} />
          </AppContextProvider>
        </QueryClientProvider>
      </Layout>
    </ErrorBoundary>
  );
};

export default App;
