import { API } from '@utils';

export const addAttendeeTickets = async (
  ticketsToAdd: IAttendeeTicket[],
  promoCode?: string
) => {
  const path = `AttendeeTicket/Add${
    promoCode ? `/?promo_code=${promoCode}` : ''
  }`;
  return API.post<IAttendeeTicket[]>(path, ticketsToAdd);
};
