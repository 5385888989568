import { registerLocale } from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import Cookies from 'js-cookie';
import { type NextRouter, useRouter } from 'next/router';
import { type ReactNode, useEffect } from 'react';

import {
  createSession,
  getSessionById,
  updateSession,
} from '@apiCalls/sessions';
import { SESSION_COOKIE_EXPIRATION, SESSION_ID_COOKIE } from '@constants';
import { handleError } from '@utils/errorHandler';

export default function Layout({ children }: { children: ReactNode }) {
  const router = useRouter();
  let eventId: string | undefined;
  if (typeof window === 'undefined') {
    // SSR
    eventId = router.query.eventId as string | undefined;
  } else {
    // NOTE: router.query.eventId is not available on initial load because of Next.js static optimization (https://stackoverflow.com/a/73685640)
    const pathMatches = window.location.pathname.match(/\/(\d+)(\/.*)?$/);
    eventId = pathMatches ? pathMatches[1] : undefined;
  }

  useEffect(() => {
    const sessionIdFromCookie = Cookies.get(SESSION_ID_COOKIE);
    registerLocale(enLocale);
    if (sessionIdFromCookie) {
      restoreSession(sessionIdFromCookie, router, eventId);
    } else {
      createAndUseSession(eventId);
    }
  }, []);

  return <>{children}</>;
}

async function createAndUseSession(eventId?: string) {
  try {
    const { userAgent } = navigator;

    const createSessionResponse = await createSession({
      State: JSON.stringify({
        userAgent,
        eventId,
      }),
    });
    if (createSessionResponse?.Key) {
      Cookies.set(SESSION_ID_COOKIE, createSessionResponse?.Key, {
        expires: SESSION_COOKIE_EXPIRATION,
      });
    }
  } catch (error) {
    handleError(error);
  }
}

async function restoreSession(
  sessionId: string,
  router: NextRouter,
  eventId?: string
) {
  try {
    const sessionData = await getSessionById(sessionId);
    const sessionState = JSON.parse(sessionData?.State ?? '') as Record<
      string,
      any
    >;
    if (!!eventId && eventId !== sessionState.eventId) {
      // save eventId
      await updateSession(sessionId, {
        State: JSON.stringify({
          ...sessionState,
          eventId,
        }),
      });
    } else if (sessionState.eventId && !eventId) {
      // eventId was in the session but missing from the URL --> load back the Event Landing page
      // WARNING: this logic wouldn't fit a more complex app where returning users can do something other than purchase
      // NOTE: Occasionally caused glitches, so disabled for now
      // router.push(`/event/${sessionState.eventId}`);
    }
  } catch (error) {
    if ((error as any).name === 'SessionNotFound') {
      Cookies.remove(SESSION_ID_COOKIE);
    }
    handleError(error);
  }
}
