import { API } from '@utils';

export const getEvent = async (eventId: number | string) => {
  return API.get<IEvent>(`Events/Get/${eventId}?get_questions=true`);
};

export const getEventSeries = async (eventSeriesId: number) => {
  return API.get<IEventSeries>(`EventSeries/${eventSeriesId}`);
};

export const getEventsByEventSeries = async (eventSeriesId: number) => {
  return API.get<IEvent>(`Events/ByEventSeries/${eventSeriesId}`);
};

export const getTimeslots = async (ticketId: number) => {
  return API.get<IEventTimeslotsResponse>(`Timeslots/Times`, {
    ticket_id: ticketId,
  });
};

export const getEventTicketDetails = async (
  event: IEvent,
  promoCode?: string
) => {
  if (event.ID === 0) {
    return;
  }
  return API.get<ITicket[]>(
    `Ticket/Event/${event.ID}?get_questions=true${
      promoCode ? `&promo_code=${promoCode}` : ''
    }`
  );
};

export const getEventSeriesTicketDetails = async (eventSeriesId: number) => {
  return API.get<ITicketSummary[]>(
    `EventSeries/${eventSeriesId}/Tickets?get_questions=true`
  );
};

export const getBrokerDetails = async (event: IEvent) => {
  if (event.ID === 0 || event.BrokerID === 0) {
    return;
  }
  return API.get<IBroker>(`Broker/Get/${event.BrokerID}`);
};

export const getTalentDetails = async (event: IEvent) => {
  if (event.ID === 0) {
    return;
  }
  return API.get<ITalent[]>(`Talent/GetByEvent?event_id=${event.ID}`);
};

export const getVenueDetails = async (event: IEvent) => {
  if (event.ID === 0) {
    return;
  }
  return API.get<IVenue>(`Venues/Get/${event.VenueID}`);
};
