import { API, getColorQueryOfEvent } from '@utils';

/** DEPRECATED */
export const saveAttendee = async (attendeeToSave: IAttendee) => {
  const response = await API.post<IAttendee[]>('Attendee/Save', [
    attendeeToSave,
  ]);
  return response?.[0];
};

export const saveAttendees = async (
  transactionId: string,
  attendeesToSave: IAttendee[]
) => {
  return API.put<IAttendee[]>(
    `Transactions/${transactionId}/Attendees`,
    attendeesToSave
  );
};

export const getAttendee = async (attendeeId: number) => {
  return API.get<IAttendee>(`Attendee/Get/${attendeeId}`);
};

export const generateAvatar = async ({
  FirstName,
  LastName,
  event,
}: IGenerateAvatarParameters) => {
  return API.get<string>(
    `Utils/GenerateAvatar?firstName=${FirstName}&lastName=${LastName}${getColorQueryOfEvent(
      event
    )}`
  );
};
