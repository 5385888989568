import * as Sentry from '@sentry/nextjs';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { ScopeLogo } from '@assets';

import classes from '../components/ErrorPage/ErrorPage.module.css';

export class HandledError extends Error {}

/**
 * Main error handler function - don't use it directly
 * @param error Error caught
 * @param info metadata provided by the Error Boundary
 */
export function handleError(
  error: Error | unknown,
  info?: {
    componentStack?: string;
    inAsync?: boolean;
    tags?: Record<string, string>;
  }
) {
  if (error instanceof HandledError) {
    return;
  }
  if (process.env.NODE_ENV === 'development') {
    console.error('Error caught by handleError():', error, info);
    if (String(error) === 'Error: Failed to load Stripe.js') {
      return;
    }
    throw error; // To allow Next.js to display it in an overlay for the developer
  }
  captureException(error, info?.inAsync === true, info?.tags);
}

/**
 * Error failback component to render when the app has an unhandled error
 */
export function ErrorFallbackComponent() {
  const router = useRouter();
  const { eventId } = router.query;
  return (
    <>
      <Head>
        <title>Scope Tickets</title>
      </Head>
      <div className={classes.errorFallbackWrapper}>
        <div className={classes.errorFallbackMessage}>
          <ScopeLogo
            fill='#0500F2'
            style={{
              height: '60px',
              padding: '20px',
              marginBottom: '20px',
            }}
          />
          <div>
            A critical error happened but we're already working on the fix.
          </div>
          {eventId && (
            <div className={classes.buttonWrapper}>
              <Link href={`/event/${eventId}`}>
                <a className={classes.errorButton}>
                  <p>Reload</p>
                </a>
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

/**
 * Handles server-side render errors
 */
export function handleRenderError(error: Error | unknown) {
  if (typeof window === 'undefined') {
    // server-side only
    handleError(error);
    return <ErrorFallbackComponent />;
  } else {
    // throw further to the Error Boundary in the browser
    throw error;
  }
}

/**
 * Captures an exception in Sentry
 *
 * @param error Error to capture
 * @param inAsync Boolean that tells whether the error happened in an async code (or during a component render)
 */
export function captureException(
  error: unknown,
  inAsync: boolean,
  tags?: Record<string, string>
) {
  Sentry.captureException(error, {
    tags: {
      ...tags,
      device: typeof window === 'undefined' ? 'server' : 'browser',
      inAsync,
    },
  });
}

/**
 * Wraps a function of a React component in the error handler that triggers the Error Boundary
 * Throwing in a setState call produces a rendering error that is picked up by the Error Boundary
 *
 * @param function_ sync function to wrap with the error handler
 * @returns the wrapped function
 */
export const withErrorBoundary = <T extends Array<any>, U>(
  function_: (...arguments_: T) => U
) => {
  const [, setErrorState] = useState();

  const wrappedFunction: (...arguments_: T) => U | void = (
    ...arguments_: T
  ) => {
    try {
      function_(...arguments_);
    } catch (error) {
      handleError(error, { inAsync: true });
      setErrorState(() => {
        throw new HandledError();
      });
    }
  };
  return wrappedFunction;
};

/**
 * Wraps a function in the error handler
 * NOTE: this function does NOT trigger the Error Boundary so there is no user feedback by default
 * @param function_ a sync or async function to wrap with the error handler
 * @returns the wrapped function
 */
export function withErrorHandler<T extends Array<any>, U>(
  function_: (...arguments_: T) => U
) {
  const wrappedFunction = (...arguments_: T) => {
    try {
      const result = function_(...arguments_);
      return result;
    } catch (error) {
      handleError(error, { inAsync: true });
    }
  };
  return wrappedFunction;
}
