import { API } from '@utils';

export const sendFeedback = async ({
  attendeeTicketKey,
  feedbackRating,
}: ISendFeedbackParameters) => {
  return API.post('Feedback', {
    AttendeeTicketKey: attendeeTicketKey,
    Rating: feedbackRating,
  });
};

export const getQuestions = async (eventId: number | string) => {
  return API.get<IQuestion[]>(`Questionnaires/Events/${eventId}/Questions`);
};

export const sendQuestionnaireResponses = async (
  cartId: string,
  body: ISendQuestionnaireResponseParameters
) => {
  return API.post<string>(`Cart/${cartId}/Questionnaires/Responses`, body);
};
